import React, { useState } from "react"
import "./assets/styles/_index.scss"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
import KamanAboutSidebar from "./KamanAboutSidebar"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import ProptvStand from "../../proptvstand/ProptvStand"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"

const videoUrl = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const videoUrlStand = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

function KamanAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,
    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              centerMode: true,
              centerPadding: "0px",
              slidesToShow: 1,
            },
          },
    
        ],
    
      }

    return (
        <>

            <div className="ProjectAboutPageSectionKaman">
                <div className="d-none d-md-block">
                    <KamanAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />

                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>

                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/kaman-developer/">
                                            <p><img src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} className="p-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/kaman-developer/about/">
                                            <p><img src={about} fluid className="" /> About Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <img src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Kaman Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection">
                                            <h4 className='m-0'>Kaman Developers</h4>
                                            <p className="mb-0">
                                                <p className="AboutExpoPara">Kaman Holding Pvt Ltd, a part of Kaman Group of companies and joint venture partner
Embassy Golflinks Park led by Mr. Sunil Gupta, embodies robust family values and traditions for
generations.Their commitment to excellence spans across diverse sectors such as land
aggregation, construction, mining, precious metals, and tourism.</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">
                                                    <p className="AboutExpoPara"> With a visionary approach to
innovation, Kaman Holding Pvt Ltd consistently strives for success. Their dedication to quality
and unwavering standards sets them apart in the industry. Under Mr. Sunil Gupta's leadership,
they continue to excel and make significant contributions. This impressive company stands as a
beacon of integrity and forward-thinking in today's business landscape.</p>
                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                            videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default KamanAbout