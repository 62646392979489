import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function KamanReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Kaman Developers</h4>
            <p className="mb-0">
                <p className="AboutExpoPara">Kaman Holding Pvt Ltd, a part of Kaman Group of companies and joint venture partner
Embassy Golflinks Park led by Mr. Sunil Gupta, embodies robust family values and traditions for
generations.Their commitment to excellence spans across diverse sectors such as land
aggregation, construction, mining, precious metals, and tourism.</p>

            </p>

            {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">With a visionary approach to
innovation, Kaman Holding Pvt Ltd consistently strives for success. Their dedication to quality
and unwavering standards sets them apart in the industry. Under Mr. Sunil Gupta's leadership,
they continue to excel and make significant contributions. This impressive company stands as a
beacon of integrity and forward-thinking in today's business landscape.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default KamanReadMore;