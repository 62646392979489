import React, { } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import KamanAbout from "../../../components/developers/kamandeveloper/KamanAbout";

const favicon_logo = require("../../../images/favicon.png");

const KamanAboutPage = () => (
  <>
    <Favicon url={favicon_logo} />

    <KamanAbout />
  </>
)

export default KamanAboutPage
